<template>
    <div class="view">
        <el-tabs v-model="accountId" @tab-click="getList">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="row.appName" :name="String(row.accountId)" v-for="(row, index) in accountMap"
                :key="index"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt10 mb10">
            <div style="margin-left: auto">
                <el-button type="primary" icon="el-icon-refresh" @click="getList"></el-button>
                <el-button class="ml10" type="primary" @click="showAddModel">添加广告</el-button>
            </div>
        </div>
        <div class="w100p d-flex flex-wrap-y" v-if="tableData.length > 0" v-loading="tableLoading">
            <div class="adContainer mr10 ml10 mb10 mb10 posre" v-for="(row, index) in tableData" :key="index">
                <el-image class="adImg br5 shadow-gray50" style="height:65vh;" :src="row.advertUrl" fit="cover"></el-image>
                <div class="adTextContent flex-c-c br5 yesSet hover_pointer"
                    :style="{ background: row.advertTitle && row.advertContent ? 'rgba(0, 0, 0, 0.3)' : '' }">
                    <div class="adText">
                        <div class="adTitle">{{ row.advertTitle }}</div>
                        <div class="adContent">{{ row.advertContent }}</div>
                    </div>
                    <div class="setAd">
                        <div class="adUpdate flex-c-c" @click="showUpdateAdImgModel(row)">
                            <div class="el-icon-edit-outline"></div>
                        </div>
                        <div class="adDel flex-c-c" @click="delAd(row)">
                            <div class="el-icon-close"></div>
                        </div>
                    </div>
                    <div class="applyType">
                        <div>{{ row.appName }}</div>
                        <br>
                        <div class="mt10" v-if="row.advertPath">路径：{{ row.advertPath }}</div>
                        <!-- <div>{{ applyTypeData[row.accountType] }}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-c-c mb30" v-else>
            <p>暂无数据</p>
        </div>
        <div>
            <el-pagination v-if="tableData.length > 0" background :page-size="pageSize" @size-change="sizeChange"
                @current-change="currentChange" :current-page="currentPage" :page-sizes="[5, 10, 15]"
                layout="total, sizes, prev, pager, next, jumper" class="" :total="total" :pager-count="5" />
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="adModelTitle"
            :visible.sync="showAdLinkModel" :destroy-on-close="true" :width="formType == 'add' ? '1200px' : '600px'" center>
            <div class="flex-j-c">
                <div class="w50p">
                    <div class="flex-a-c" v-if="formType == 'add'">
                        <el-button type="primary" @click="showAdImgModelFun">选择广告图</el-button>
                        <div class="ml20">已选 {{ checkedAdImg.length }} 张</div>
                    </div>
                    <div class="mt20 adImgArr" v-if="checkedAdImg.length > 0">
                        <el-carousel height="552px" type="card" indicator-position="none" :autoplay="false"
                            v-if="formType == 'add'">
                            <el-carousel-item v-for="(row, index) in checkedAdImg" :key="index">
                                <div class="adContainer posre adContainer2">
                                    <el-image class="adImg br5 shadow-gray50" :src="row.advertUrl" fit="cover"></el-image>
                                    <div class="adTextContent flex-c-c br5 yesSet hover_pointer"
                                        :style="{ background: row.advertTitle && row.advertContent ? 'rgba(0, 0, 0, 0.3)' : '' }">
                                        <div class="adText">
                                            <div class="adTitle">{{ row.advertTitle }}</div>
                                            <div class="adContent">{{ row.advertContent }}</div>
                                        </div>
                                        <div class="setAd">
                                            <div class="adDel flex-c-c">
                                                <div class="el-icon-close" @click="delCheckedImg(row.adId)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt10 flex-c-c">
                                    <el-select v-model="row.advertPath" placeholder="跳转路径" class="mt15 w230">
                                        <el-option label="" :value="''">
                                        </el-option>
                                        <el-option v-for="(label, id) in advertisementUrlData" :key="id" :label="label"
                                            :value="id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <div v-else>
                            <div v-for="(row, index) in checkedAdImg" :key="index">
                                <div class="adContainer posre adContainer2">
                                    <el-image class="adImg br5 shadow-gray50" :src="row.advertUrl" fit="cover"></el-image>
                                    <div class="adTextContent flex-c-c br5"
                                        :style="{ background: row.advertTitle && row.advertContent ? 'rgba(0, 0, 0, 0.3)' : '' }">
                                        <div class="adText">
                                            <div class="adTitle">{{ row.advertTitle }}</div>
                                            <div class="adContent">{{ row.advertContent }}</div>
                                        </div>
                                        <div class="setAd">
                                            <div class="adDel flex-c-c">
                                                <div class="el-icon-close" @click="delCheckedImg(row.adId)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt10 flex-c-c">
                                    <el-select v-model="row.advertPath" placeholder="跳转路径" class="mt15 w230">
                                        <el-option label="" :value="''">
                                        </el-option>
                                        <el-option v-for="(label, id) in advertisementUrlData" :key="id" :label="label"
                                            :value="id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-c-c mt30 h50" v-else>
                        <p class="fs9">请选择广告图</p>
                    </div>
                </div>
                <div class="w50p pl20" v-if="formType == 'add'">
                    <div class="textc">小程序选择</div>
                    <div class="d-flex">
                        <el-table class="mt20" v-loading="loading" ref="applyTable" :data="accountMap" border
                            highlight-current-row @current-change="selectApply">
                            <!-- <el-table-column type="index" align="center" label="序号" /> -->
                            <el-table-column type="index" align="center" label="序号" />
                            <el-table-column prop="appName" align="center" label="小程序名称" show-overflow-tooltip />
                            <el-table-column prop="accountType" align="center" label="类型" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ applyTypeData[scope.row.accountType] }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAdLinkModel = false">取 消</el-button>
                <el-button type="primary" @click="adSubmit" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择广告图" :visible.sync="showAdImgModel"
            :destroy-on-close="true" width="1000px" center>
            <el-button type="primary" @click="allAdImgFun">全选</el-button>
            <div class="mt20">
                <div class="w100p d-flex flex-wrap-y" v-if="adImgData.length > 0" v-loading="adImgLoading">
                    <div class="adContainer mr10 ml10 mb10 mb10 posre" @click="checkedAdImgFun(row)"
                        v-for="(row, index) in adImgData" :key="index">
                        <el-image class="adImg br5 shadow-gray50 h370" :src="row.advertUrl" fit="cover"></el-image>
                        <div class="adTextContent flex-c-c br5 hover_pointer"
                            :style="{ background: row.advertTitle && row.advertContent ? 'rgba(0, 0, 0, 0.3)' : '' }">
                            <div class="adText">
                                <div class="adTitle">{{ row.advertTitle }}</div>
                                <div class="adContent">{{ row.advertContent }}</div>
                            </div>
                        </div>
                        <div class="checkedImg">
                            <el-checkbox v-model="checkedAdImgValue[row.adId]"></el-checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex-c-c mb30" v-else>
                    <p>请到广告图库中上传图片</p>
                </div>
            </div>
            <div>
                <el-pagination v-if="adImgData.length > 0" background :page-size="adPageSize" @size-change="adSizeChange"
                    @current-change="adCurrentChange" :current-page="adCurrentPage" :page-sizes="[5, 10, 15]"
                    layout="total, sizes, prev, pager, next, jumper" class="" :total="adTotal" :pager-count="5" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAdImgModel = false">取 消</el-button>
                <el-button type="primary" @click="submitCheckedImg">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { setAdvertLink, getAdvertModelList, getByDictType, getAdvertLinkList, delAdvertLinkList } from "@/api/miniProgram";
import { getCompanyAccountList } from "@/api/authorization"
export default {
    name: "appletAdTable",
    data() {
        return {
            //列表配置
            tableLoading: false, //表格加载
            tableData: [],
            userInfo: this.$store.state.loginRoot.userInfo,
            //编辑新增配置
            adModelTitle: "",
            showAdLinkModel: false,
            advertUrl: [],
            formType: "",
            updateId: 0,
            loadingBtn: false,
            total: 0,
            currentPage: 1,
            pageSize: 5,
            accountId: "all",
            accountMap: [],
            applyTypeData: {
                1: '名片端',
                2: 'crm端',
                8: '园区端',
                9: '社团端',
                10: '政务端'
            },
            //广告图关联配置
            checkedAdImg: [],//选中的广告图
            checkedApply: {},//选中的小程序
            loading: false, //表格加载
            showAdImgModel: false,
            //广告图列表配置
            adPageSize: 5,
            adImgData: [],
            adImgLoading: false,
            adCurrentPage: 1,
            adTotal: 0,
            checkedAdImgValue: {},
            checkedAdImgArr: {},
            advertisementUrlData: {},
        };
    },
    created() {
        this.getList();
        this.getAppListInfo();
    },
    methods: {
        /**@method 选中广告图 */
        checkedAdImgFun(row) {
            this.$set(this.checkedAdImgValue, row.adId, !this.checkedAdImgValue[row.adId]);
            if (this.checkedAdImgValue[row.adId]) {
                this.$set(this.checkedAdImgArr, row.adId, row);
            } else {
                this.$delete(this.checkedAdImgArr, row.adId);
            }
        },
        /**@method 全选广告图 */
        allAdImgFun() {
            for (let row of this.adImgData) {
                this.$set(this.checkedAdImgValue, row.adId, true);
                this.$set(this.checkedAdImgArr, row.adId, row);
            }
        },
        delCheckedImg(adId) {
            for (let key in this.checkedAdImg) {
                let row = this.checkedAdImg[key]
                if (row.adId === adId) {
                    this.$delete(this.checkedAdImg, key);
                    break;
                }
            }
        },
        /**@method 确认选中的广告图*/
        submitCheckedImg() {
            for (let key in this.checkedAdImgArr) {
                let row = this.checkedAdImgArr[key];
                row.advertPath = "";
                this.checkedAdImg.push(row);
            }
            this.showAdImgModel = false;
        },
        /**@method 显示选择广告图 */
        showAdImgModelFun() {
            this.showAdImgModel = true;
            this.adCurrentPage = 1;
            this.checkedAdImgValue = {};
            this.checkedAdImgArr = {};
            for (let row of this.checkedAdImg) {
                this.$set(this.checkedAdImgValue, row.adId, true);
                this.$set(this.checkedAdImgArr, row.adId, row);
            }
            this.getAdList()
        },
        /**@method 广告图分页 */
        adSizeChange(val) {
            this.adCurrentPage = 1
            this.adPageSize = val;
            this.getAdList()
        },
        /**@method 广告图切换显示行 */
        adCurrentChange(val) {
            this.adCurrentPage = val;
            this.getAdList()
        },
        /**@method 获取广告图 */
        async getAdList() {
            let params = {
                pageSize: this.adPageSize,
                pageNum: this.adCurrentPage,
            };
            try {
                this.adImgLoading = true;
                let res = await getAdvertModelList(params)
                this.adImgLoading = false;

                const { data } = res;
                let adImgData = [];
                // let checkedAdImgValue=this.checkedAdImgValue;
                for (let row of data.pageInfo.list) {
                    adImgData.push(row);
                    // checkedAdImgValue[row.adId]=false;
                }
                // this.checkedAdImgValue=checkedAdImgValue;
                this.adImgData = adImgData;
                this.adTotal = data.pageInfo.total;
            } catch (error) {
               //-console.log(error);
                this.adImgLoading = false;
                this.adImgData = [];
            }
        },
        /**@method 选中关联的小程序 */
        selectApply(data) {
            this.checkedApply = data;
        },
        /**@method 获取小程序列表 */
        async getAppListInfo() {
            this.loading = true;
            const result = await getCompanyAccountList({ appid: true })
            this.loading = false;
            //-console.log(result)
            if (result.data) {
                let tmp = [];
                result && result.data.map(val => {
                    let accountTypes = [1, 2, 8, 9, 10];//只显示 名片端 crm端 园区端 社团端 政务端
                    if (accountTypes.indexOf(val.accountType) != -1) {
                        tmp.push(val);
                    }
                });
                this.accountMap = tmp;
            }
        },
        /**@method 获取字典数据 */
        getByDictType() {
            return getByDictType({ dictType: "advertisement_url", status: 0 }).then(res => {
                if (res.code === 200) {
                    let advertisementUrlData = {};
                    for (let row of res.data.sysDictDataList) {
                        advertisementUrlData[row.dictValue] = row.dictLabel;
                    }
                    this.advertisementUrlData = advertisementUrlData;
                }
            })
        },
        sizeChange(val) {
            this.currentPage = 1
            this.pageSize = val;
            this.getList()
        },
        currentChange(val) {
            this.currentPage = val;
            this.getList()
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyId: this.userInfo.companyId,
            };
            if (this.accountId != 'all') {
                params.accountId = this.accountId;
            }
            try {
                this.tableLoading = true;
                let res = await getAdvertLinkList(params)
                this.tableLoading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
               //-console.log(error);
                this.tableLoading = false;
                this.tableData = [];
            }
        },
        showAddModel() {
            this.showAdLinkModel = true;
            this.adModelTitle = "添加广告"
            this.checkedAdImg = [];
            this.checkedApply = {};
            this.formType = "add";
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateAdImgModel(row) {
            this.showAdLinkModel = true;
            this.adModelTitle = "编辑广告"
            this.checkedAdImg = [row];
            this.checkedApply = {};
            this.formType = "update";
            this.updateId = row.linkId;
        },
        /**@method 取消关联 */
        delAd(row) {
            this.$confirm("是否确认取消该广告关联。", "提示", {
                onConfirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "info ",
            }).then(() => {
                delAdvertLinkList({
                    linkId: row.linkId
                }).then(res => {
                    this.getList();
                })
            })
        },
        /**@method 修改添加广告图 */
        adSubmit() {
            let parmas = {}
            let message = ""
            if (this.formType === 'update') {
                parmas = {
                    linkId: this.updateId,
                    advertPath: this.checkedAdImg[0].advertPath
                }
                message = "编辑成功";
            } else {
                if (!this.checkedAdImg.length > 0) {
                    this.$message({
                        type: "info",
                        message: "请选择广告图"
                    })
                    return;
                }
                if (!this.checkedApply.accountId) {
                    this.$message({
                        type: "info",
                        message: "请选择小程序"
                    })
                    return;
                }
                let addJson = [];
                for (let row of this.checkedAdImg) {
                    addJson.push({
                        advertPath: row.advertPath,
                        adId: row.adId
                    });
                }
                parmas = {
                    accountId: this.checkedApply.accountId,
                    addJson: addJson
                }
                message = "添加成功"
            }
            this.loadingBtn = true;
            setAdvertLink(parmas).then(res => {
                this.loadingBtn = false;
                if (res.code === 200) {
                    this.$message({
                        message: message,
                        type: "success"
                    })
                    this.showAdLinkModel = false;
                    this.getList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                this.loadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })

        }
    },
};
</script>
<style>
.adUpload .el-upload--picture-card,
.adUpload .el-upload-list--picture-card .el-upload-list__item {
    height: 500px !important;
    width: 250px !important;
    line-height: 500px !important;
}

.adUpload .el-upload-list--picture-card .el-upload-list__item-status-label .el-icon-check {
    position: absolute !important;
    bottom: 1px !important;
    left: 13px !important;
    margin: 0 !important;
}
</style>
<style lang="scss" scoped>
.adContainer {
    width: calc(20% - 20px);
    min-width: calc(20% - 20px);
    display: inherit;

    .adImg {
        width: 100%;
    }

    .checkedImg {
        position: absolute;
        top: 10px;
        left: 10px;
    }
}

.adContainer2 {
    width: 230px !important;
    height: 500px !important;
    margin: 0 auto;

    .adImg {
        width: 100%;
        height: 100%;
    }
}

.adTextContent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
    padding: 0 20px;

    .adTitle {
        font-weight: bold;
        font-size: 20px;
    }

    .adContent {
        font-size: 20px;
    }

    .setAd {
        display: none;

        .adUpdate {
            border-radius: 50%;
            background: #51cbcd;
            width: 65px;
            height: 65px;

            >div {
                font-size: 35px;
            }
        }

        .adDel {
            margin-top: 20px;
            border-radius: 50%;
            background: #F56C6C;
            width: 65px;
            height: 65px;

            >div {
                font-size: 35px;
            }
        }
    }

    .applyType {
        position: absolute;
        right: 0;
        top: 20px;
        text-align: right;

        >div {
            display: inline-block;
            background: #51cbcd;
            padding: 5px 0;
            padding-left: 13px;
            padding-right: 5px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }
}

.yesSet:hover {
    background: rgba(0, 0, 0, 0.3);
}

.yesSet:hover .adText {
    display: none;
}

.yesSet:hover .setAd {
    display: block;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>